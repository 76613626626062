import _taggedTemplateLiteral from "/tmp/7cc5fa2/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px;\n  background-color: red;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

import React from "react";
import RNButton from "react-native-web/dist/exports/Button";
import styled from "styled-components";
var ButtonView = styled(RNButton)(_templateObject());

var Button = function Button(props) {
  return React.createElement(ButtonView, _extends({
    color: "black"
  }, props));
};

export { Button };