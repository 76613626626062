import React, { useState } from "react";
import "./App.css";
import { Button, Input } from "@studytracks/components/lib/web";

const App: React.FC = () => {
  const [state, setState] = useState({
    email: "",
    password: ""
  });
  // eslint-disable-next-line no-console
  console.log("TCL: App:React.FC -> state", state);
  return (
    <div className="App">
      <header className="App-header">
        <Input
          onChangeText={email => setState(s => ({ ...s, email }))}
          placeholder="Email"
        />

        <Input
          onChangeText={password => setState(s => ({ ...s, password }))}
          placeholder="Password"
          secureTextEntry
          textContentType="password"
        />

        <Button
          onPress={() => {
            // eslint-disable-next-line no-alert
            window.alert("Todo.");
          }}
          title="Login"
        />
      </header>
    </div>
  );
};

export { App };
